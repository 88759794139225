<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- 

          STOCK OPNAME FIELD REQUIRED

         -->
        <!-- Input Birt Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <!-- <v-dialog
            ref="dialog"
            v-model="modal_birt_date"
            :return-value.sync="form.checking_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-birt-date"
                v-model="form.checking_date"
              >
              </b-form-input>
            </template>

            <v-date-picker
              locale="id"
              v-if="modal_birt_date"
              v-model="form.checking_date"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_birt_date = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.checking_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog> -->

          <v-dialog
            ref="dialog"
            v-model="modal_birt_date"
            :return-value.sync="form.checking_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-input-group>
                <b-input-group-prepend>
                  <button
                    class="btn btn-secondary"
                    type="button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fas fa-calendar"></i>
                  </button>
                </b-input-group-prepend>
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="form.checking_date"
                  placeholder="(YYYY-MM-DD)"
                  @input="formaterDate"
                >
                </b-form-input>
              </b-input-group>
            </template>

            <v-date-picker
              v-if="modal_birt_date"
              v-model="form.checking_date"
              locale="id"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_birt_date = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.checking_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input employee -->
        <!-- 
        <div class="row px-0">
          <div class="form-control"
             @click="$bvModal.show('modal-employee')"
          >
            {{display.employee_name}}
          </div>
        </div> -->
        <b-form-group
          id="input-group-trigger-modal-employee"
          label="Karyawan :"
          label-for="input-trigger-modal-employee"
        >
          <b-form-input
            id="input-trigger-modal-employee"
            v-model="display.employee_name"
            placeholder="Pilih karyawan"
            readonly
            @click="$bvModal.show('modal-employee')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.employee_id }}</small>
        </b-form-group>

        <!-- 

          END STOCK OPNAME FIELD REQUIRED

         -->

        <!-- 

          DETAIL STOCK OPNAME FIELD REQUIRED

         -->

        <b-form-group
          id="input-group-trigger-modal-medicine"
          label="Obat & Alkes:"
          label-for="input-trigger-modal-medicine"
        >
          <b-form-input
            id="input-trigger-modal-medicine"
            v-model="display.medicine_name"
            placeholder="Pilih Obat"
            readonly
            @click="$bvModal.show('modal-medicine')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.medicine_id }}</small>
        </b-form-group>

        <div class="overflow-auto">
          <div class="bg-light" v-if="coll_medicine.length > 0">
            <table class="table" v-if="coll_medicine.length > 0">
              <tr>
                <th>Nama</th>
                <th>Stok Di Sistem</th>
                <th>Stok Fisik</th>
                <th>Selisih Stok</th>
                <th></th>
              </tr>
              <tr v-for="mdc in coll_medicine" :key="mdc.id">
                <td>{{ mdc.name }}</td>
                <td>{{ mdc.current_stock }}</td>
                <td>{{ mdc.checking_stock }}</td>
                <td>{{ mdc.difference_stock }}</td>
                <td>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover.left
                    title="Hapus Obat dan Alkes"
                    type="button"
                    @click="removeMedicine(mdc.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- 

          END DETAIL STOCK OPNAME FIELD REQUIRED

         -->

        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/stock-opnames/list')"
        >
          Batal
        </b-button>
      </b-form>

      <b-modal
        ref="my-modal"
        hide-footer
        title="Resep Obat"
        @hide="hideModalCancel"
      >
        <b-form @submit.stop.prevent="medicineFormOnsubmit">
          <!-- <div class="d-block text-center"> -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="coll_medicine_form.name"
              placeholder="Nama obat"
              readonly
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-quantity"
            label="Kuantitas:"
            label-for="input-quantity"
          >
            <b-form-input
              id="input-quantity"
              v-model="coll_medicine_form.quantity"
              placeholder="Kuantitas"
              required
            ></b-form-input>
          </b-form-group> -->
          <!-- </div> -->
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>

      <ModalEmployee
        @chosenEmployee="setEmployee"
        @submitedEmployee="setEmployee"
      />

      <ModalMedicine
        @chosenMedicine="setPrescription"
        :selectedItem="coll_medicine"
        :checkbox="true"
        :isSortcut="true"
        :isAbjad="true"
      />
    </div>
  </v-app>
</template>

<script>
import ModalMedicine from "@/component/general/ModalMedicineStockOpname.vue";
import ModalEmployee from "@/component/general/ModalEmployee.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalEmployee,
    ModalMedicine,
  },

  data() {
    return {
      error: {
        name: "",
        blood_type_id: null,
        age: null,
        birt_date: null,
        last_donation_date: "",
        phone: "",
        gender: null,
        quantity: null,
        email: "",
        id_card_number: null,
        address: "",
      },
      modal_birt_date: false,

      // medicines
      coll_medicine_form: {
        id: "",
        name: "",
        current_stock: "",
        checking_stock: "",
        difference_stock: "",
      },

      coll_medicine: [],
      coll_medicine_str: "",

      display: {
        medicine_name: "",
        employee_name: "",
      },
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.checking_date = this.form.checking_date + "-";
      }
      if (val.length == 7) {
        this.form.checking_date = this.form.checking_date + "-";
      }
    },
    
    setEmployee(value) {
      this.form.employee_id = value.id;
      this.display.employee_name = value.name;
      this.$bvModal.hide("modal-employee");
    },

    async setPrescription(value) {
      // if(this.isPackagePrescription == false){
      // await
      let res = await module.get(
        `medicine-batch-stocks/get-by-medicine/${value.id}`
      );

      console.log(res, "ressssss");
      console.log("tangkap", value);
      if (res.length != 0) {
        if (this.coll_medicine.length > 0) {
          let a;
          for (a = 0; a < this.coll_medicine.length; a++) {
            if (this.coll_medicine[a].id == value.id) {
              this.coll_medicine.splice(a, 1);
              if (this.coll_medicine.length == 0) {
                this.display.medicine_name = "";
                break;
              } else if (this.coll_medicine.length > 0) {
                this.displayMedicineName(this.coll_medicine);
                break;
              }
              break;
            } else if (a == this.coll_medicine.length - 1) {
              // this.$bvModal.hide("modal-medicine");
              console.log("value", value);
              await this.medicine_id;
              this.medicine_id = value.id;
              console.log("medicine_id", this.medicine_id);
              // this.coll_medicine_form.id = "";
              this.coll_medicine_form.name = "";
              this.coll_medicine_form.current_stock = "";
              this.coll_medicine_form.checking_stock = "";
              this.coll_medicine_form.difference_stock = "";
              this.setMedicine(value.id);
            }
          }
        } else {
          // this.$bvModal.hide("modal-medicine");
          console.log("value", value);
          await this.medicine_id;
          this.medicine_id = value.id;
          console.log("medicine_id", this.medicine_id);
          // this.coll_medicine_form.id = "";
          this.coll_medicine_form.name = "";
          this.coll_medicine_form.current_stock = "";
          this.coll_medicine_form.checking_stock = "";
          this.coll_medicine_form.difference_stock = "";
          this.setMedicine(value.id);
        }
      } else {
        Swal.fire(
          "Perhatian",
          "Obat yang anda pilih tidak memiliki riwayat stok , silahkan tambahkan stok terlebih dahulu",
          "warning"
        );
      }
      // }
    },

    setMedicine(id) {
      ApiService.get("medicines/" + id).then((response) => {
        console.log("medicines", response.data.data);
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.coll_medicine_form.name = response.data.data.name;
        this.coll_medicine_form.id = response.data.data.id;
        this.coll_medicine_form.current_stock = response.data.data.stock;
        this.coll_medicine_form.checking_stock = 0;
        this.coll_medicine_form.difference_stock = 0;

        this.setMedicineSubmit();
        this.medicineFormOnsubmit();;
        // this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    removeMedicine($id) {
      console.log("remove batch", $id);
      let a;
      for (a = 0; a < this.coll_medicine.length; a++) {
        if (this.coll_medicine[a].id == $id) {
          this.coll_medicine.splice(a, 1);
          console.log("hasil", this.coll_medicine);
        }
      }
      this.displayMedicineName(this.coll_medicine);
    },

    medicineFormOnsubmit() {
      // this.coll_medicine_form.total_price =
      //   parseInt(this.coll_medicine_form.quantity) *
      //   parseInt(this.coll_medicine_form.sales_price);
      // this.coll_medicine_form.total_price = parseInt(
      //   this.coll_medicine_form.total_price
      // ).toLocaleString("id-ID");
      // this.coll_medicine_form.sales_price = parseInt(
      //   this.coll_medicine_form.sales_price
      // ).toLocaleString("id-ID");

      this.medicine_collection_submit = true;
      let clone = { ...this.coll_medicine_form };
      this.coll_medicine.push(clone);

      console.log("hasil", this.coll_medicine);
      this.displayMedicineName(this.coll_medicine);
      // this.hideModal();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        console.log("tidak tekan submit");
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    getDisplayData() {
      if (this.form.employee_id != "") {
        this.display.employee_name = this.form.employee_name;
      }
    },

    async formOnsubmit() {
      let medicine = JSON.stringify(this.coll_medicine);;
      this.form.medicine = medicine;;
      console.log(this.form,  "==================== FORM ===================");
      // Make Request
      let response = await module.submit(this.form, this.route);;
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/stock-opnames");
      }
    },
  },

  mounted() {
    this.getDisplayData();;
    // this.getAge(new Date().toISOString().substr(0, 10))
  },
};
</script>

<style>
</style>